import roadTransportImage from '../assets/images/15.jpg';
import consultationImage from '../assets/images/28.jpg';
import warehousingImage from '../assets/images/8.jpg';
import { motion } from 'framer-motion';
import airFreightImage from '../assets/images/12.jpg';
import ServiceCard from '../components/ServiceCard';
import React, { useState } from 'react';
import { FaPlane, FaShip, FaTruck, FaWarehouse, FaFileImport, FaProjectDiagram, FaChartLine, FaConciergeBell, FaShoppingCart } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import seaFreightImage from '../assets/images/11.jpg';
import importExportImage from '../assets/images/21.jpg';
import projectCargoImage from '../assets/images/24.jpg';
import hospitalityImage from '../assets/images/31.jpg';
import generalTradeImage from '../assets/images/general-trade.jpg'; // Add an appropriate image for General Trade

const Services = ({ isDarkMode }) => {
  const { t } = useTranslation();
  const [expandedServices, setExpandedServices] = useState({});

  const toggleExpand = (index) => {
    setExpandedServices(prev => ({
      ...prev,
      [index]: !prev[index]
    }));
  };

  const services = [
    {
      title: t('AIR CARGO SHIPMENT'),
      description: t('Your one-stop solution for efficient and reliable air cargo services. Whether you need to transport small parcels or heavy freight, we are here to provide you with seamless air shipment solutions tailored to your specific needs. Our team of experts is dedicated to delivering your goods safely and on time, ensuring that they reach their destination without delay. Safety and security are paramount to us. We strictly adhere to international regulations and industry standards when it comes to handling and transporting your cargo.'),
      image: airFreightImage,
      icon: FaPlane,
    },
    {
      title: t('SEA CARGO SHIPMENT'),
      description: t('We offer comprehensive and cost-effective solutions for transporting goods across the seas, ensuring efficient delivery and seamless supply chain operations. We understand the importance of seamless supply chain management and timely delivery of goods to drive your business success. With our extensive network of shipping partners and state-of-the-art technology, we provide reliable and efficient sea cargo services tailored to your specific needs.'),
      image: seaFreightImage,
      icon: FaShip,
    },
    {
      title: t('IMPORT & EXPORT AGENCY'),
      description: t('We understand the importance of efficient customs clearance and smooth logistics operations in international trade. As a trusted clearing and forwarding company, we specialize in providing comprehensive services to help businesses navigate complex customs procedures and optimize their supply chains for seamless import and export of goods. Our expert team ensures compliance with all regulations and streamlines the entire process for you.'),
      image: importExportImage,
      icon: FaFileImport,
    },
    {
      title: t('WAREHOUSING AND DISTRIBUTION'),
      description: t("We understand the importance of efficient storage, handling, and distribution of goods in today's fast-paced business environment. With our comprehensive warehousing and distribution services, we aim to meet your unique needs and optimize your supply chain operations. Our distribution services are designed to streamline your product flow and deliver your goods to their designated destinations on time, ensuring customer satisfaction and business growth."),
      image: warehousingImage,
      icon: FaWarehouse,
    },
    {
      title: t('TRANSPORTATION SERVICES'),
      description: t('We understand the importance of reliable and efficient transportation in ensuring the smooth flow of goods in domestic and international trade. Whether you have time-sensitive shipments, perishable goods or high-value cargo, our dedicated team will work closely with leading airlines and shipping lines to arrange the most suitable carrier and mode of transport, handle all necessary documentation, and ensure the secure delivery of your goods from port of entry to final destination.'),
      image: roadTransportImage,
      icon: FaTruck,
    },
    {
      title: t('PROJECT CARGO HANDLING'),
      description: t('We provide comprehensive solutions for the transportation and logistics management of project cargo. Whether you are involved in large-scale infrastructure projects, heavy machinery installations, or oversized cargo shipments, we have the expertise and experience to ensure the successful execution of your project. Our project cargo handling services encompass a range of industries, including construction, oil and gas, mining, renewable energy, and more. We understand the unique challenges associated with these complex logistics operations and we are committed to delivering efficient and reliable solutions tailored to your specific needs.'),
      image: projectCargoImage,
      icon: FaProjectDiagram,
    },
    {
      title: t('BUSINESS CONSULTATION'),
      description: t('By choosing our business consulting services, you gain access to a team of experienced professionals who are dedicated to helping you overcome challenges and achieve your business objectives. We provide strategic insights, market analysis, and tailored solutions to drive growth and efficiency in your operations.'),
      image: consultationImage,
      icon: FaChartLine,
    },
    {
      title: t('HOSPITALITY'),
      description: t("We provide a range of exceptional services to make your travel experience convenient, comfortable and enjoyable. Whether you're arriving at the airport, in need of hotel reservations, planning a tour, or requiring a rental car, we have you covered. Our hospitality services ensure that every aspect of your journey is taken care of, allowing you to focus on your business or leisure activities without worry."),
      image: hospitalityImage,
      icon: FaConciergeBell,
    },
    {
      title: t('GENERAL TRADE'),
      description: t('Our General Trade service offers a versatile solution for all your miscellaneous purchasing and sales needs. Whether you are looking to buy or sell a wide range of products, we provide a seamless and efficient trading experience. Our team is committed to ensuring that your transactions are smooth and successful, leveraging our extensive network and market expertise to meet your specific requirements.'),
      image: generalTradeImage,
      icon: FaShoppingCart,
    },
  ];

  return (
    <div className={`bg-gradient-to-b ${isDarkMode ? 'from-gray-800 to-gray-900' : 'from-brand-green-50 to-brand-green-100'} min-h-screen py-12`}>
      <div className="container mx-auto px-4">
        <motion.h1
          className="text-4xl font-bold text-center text-brand-green-800 dark:text-brand-green-200 mb-12"
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {t('Our Services')}
        </motion.h1>
        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          {services.map((service, index) => (
            <div
              key={index}
              className={`${
                index === services.length - 1 && services.length % 2 !== 0
                  ? 'md:col-span-2'
                  : ''
              }`}
            >
              <ServiceCard
                {...service}
                index={index}
                isExpanded={expandedServices[index]}
                toggleExpand={() => toggleExpand(index)}
                isDarkMode={isDarkMode}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Services;

