import React from 'react';
import { FaShip, FaPlane, FaTruck, FaGlobe } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const WhyChooseUs = ({ isDarkMode }) => {
  const { t } = useTranslation();
  const reasons = [
    { icon: <FaShip />, title: t('Sea Freight'), description: t('Efficient and cost-effective sea freight solutions.') },
    { icon: <FaPlane />, title: t('Air Freight'), description: t('Fast and reliable air freight services worldwide.') },
    { icon: <FaTruck />, title: t('Land Transport'), description: t('Comprehensive land transport network.') },
    { icon: <FaGlobe />, title: t('Global Coverage'), description: t('Extensive network covering major global routes.') },
  ];

  return (
    <section className={`py-16 ${isDarkMode ? 'bg-gray-900' : 'bg-brand-green-100'}`}>
      <div className="container mx-auto px-4">
        <h2 className={`text-4xl font-bold mb-8 text-center ${isDarkMode ? 'text-white' : 'text-brand-green-800'}`}>{t('Why Choose SOCOCOMSARL')}</h2>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {reasons.map((reason, index) => (
            <div key={index} className={`p-6 rounded-lg shadow-lg ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
              <div className={`text-4xl mb-4 ${isDarkMode ? 'text-yellow-400' : 'text-brand-yellow-500'}`}>{reason.icon}</div>
              <h3 className={`text-xl font-bold mb-2 ${isDarkMode ? 'text-white' : 'text-brand-green-700'}`}>{reason.title}</h3>
              <p className={`${isDarkMode ? 'text-gray-300' : 'text-brand-green-600'}`}>{reason.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyChooseUs;