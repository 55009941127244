import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const CallToAction = ({ isDarkMode }) => {
  const { t } = useTranslation();
  return (
    <section className={`py-16 ${isDarkMode ? 'bg-gray-900' : 'bg-brand-green-100'}`}>
      <div className="container mx-auto px-4">
        <div className={`rounded-lg shadow-lg p-8 md:p-12 flex flex-col md:flex-row items-center justify-between ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
          <div className="mb-6 md:mb-0 md:mr-8">
            <h2 className={`text-3xl md:text-4xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-brand-green-800'}`}>
              {t("Ready to Optimize Your Logistics?")}
            </h2>
            <p className={`text-lg mb-4 ${isDarkMode ? 'text-gray-300' : 'text-brand-green-600'}`}>
              {t("Let's discuss how SOCOCOMSARL can streamline your supply chain and boost your business efficiency.")}
            </p>
          </div>
          <div className="flex flex-col sm:flex-row space-y-4 sm:space-y-0 sm:space-x-4">
            <Link
              to="/contact"
              className={`font-bold py-3 px-6 rounded-full transition duration-300 text-center ${isDarkMode ? 'bg-yellow-500 text-gray-900 hover:bg-yellow-600' : 'bg-brand-yellow-500 text-brand-green-900 hover:bg-brand-yellow-600'}`}
            >
              {t("Get a Quote")}
            </Link>
            <Link
              to="/services"
              className={`font-bold py-3 px-6 rounded-full transition duration-300 text-center ${isDarkMode ? 'bg-green-600 text-white hover:bg-green-700' : 'bg-brand-green-600 text-white hover:bg-brand-green-700'}`}
            >
              {t("Our Services")}
            </Link>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CallToAction;

