import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { FaClock, FaNetworkWired, FaGlobe, FaCheckCircle, FaHeadset, FaTruck, FaRegHandshake, FaEye, FaBalanceScale } from 'react-icons/fa';

const About = ({ isDarkMode }) => {
  const { t } = useTranslation();
  const fadeInUp = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };

  const reasons = [
    { icon: FaClock, title: t("Extensive Experience"), text: t("Years of hands-on experience in the logistics industry, equipped to handle a wide range of logistical challenges.") },
    { icon: FaNetworkWired, title: t("Comprehensive Services"), text: t("One-stop-shop for all logistics needs including air freight, sea freight, ground transportation, customs clearance, warehousing, and distribution.") },
    { icon: FaGlobe, title: t("Global Reach"), text: t("Wide network of partners and agents worldwide, offering efficient and cost-effective global coverage.") },
    { icon: FaCheckCircle, title: t("Commitment to Compliance"), text: t("Prioritizing compliance with customs regulations and trade laws, ensuring smooth customs clearance.") },
    { icon: FaHeadset, title: t("Excellent Customer Service"), text: t("Dedicated team always available to address inquiries, provide updates, and support throughout the logistics process.") },
    { icon: FaTruck, title: t("Cutting-Edge Technology"), text: t("State-of-the-art tracking system providing real-time visibility and transparency throughout the transportation process.") }
  ];

  return (
    <div className={`bg-gradient-to-b ${isDarkMode ? 'from-gray-800 to-gray-900' : 'from-brand-green-50 to-brand-green-100'} min-h-screen py-16`}>
      <div className="container mx-auto px-4">
        <motion.h1
          className={`text-4xl font-bold text-center ${isDarkMode ? 'text-white' : 'text-brand-green-800'} mb-12`}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {t("ABOUT SOCOCOM SARL")}
        </motion.h1>

        <motion.div
          className={`shadow-lg rounded-lg p-8 mb-12 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'}`}
          {...fadeInUp}
        >
          <h2 className={`text-2xl font-bold mb-4 ${isDarkMode ? 'text-white' : 'text-brand-green-800'}`}>{t("Your Trusted Logistics Partner")}</h2>
          <p className="mb-4">
            {t("At Sococom, we're more than just a transport and logistics company. We're your strategic partner in navigating the complexities of global supply chains. With years of experience and a commitment to excellence, we deliver tailored solutions that drive your business forward.")}
          </p>
          <p className="mb-4">
            {t("Our team of experts combines industry knowledge with cutting-edge technology to ensure your goods are delivered safely, efficiently, and on time. From sea freight to air cargo, warehousing to customs clearance, we offer end-to-end solutions that simplify your logistics operations.")}
          </p>
        </motion.div>

        <div className="grid grid-cols-1 md:grid-cols-3 gap-8 mb-12">
          {[
            { icon: FaRegHandshake, title: t("Our Mission"), text: t("To provide efficient and reliable logistics solutions that exceed our customers' expectations, driving growth and success for businesses worldwide.") },
            { icon: FaEye, title: t("Our Vision"), text: t("To be the leading global logistics provider, known for innovation, sustainability, and unparalleled customer service in every corner of the world.") },
            { icon: FaBalanceScale, title: t("Our Values"), text: t("Integrity, Excellence, Innovation, Sustainability, and Customer-Centricity form the foundation of everything we do at Sococom.") }
          ].map((item, index) => (
            <motion.div
              key={index}
              className={`shadow-lg rounded-lg p-6 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'}`}
              {...fadeInUp}
              transition={{ duration: 0.5, delay: 0.1 * index }}
            >
              <item.icon className={`text-4xl mb-4 mx-auto ${isDarkMode ? 'text-brand-yellow-500' : 'text-brand-green-600'}`} />
              <h3 className={`text-xl font-bold mb-2 text-center ${isDarkMode ? 'text-white' : 'text-brand-green-800'}`}>{item.title}</h3>
              <p className="text-center">{item.text}</p>
            </motion.div>
          ))}
        </div>

        <motion.div
          className={`shadow-lg rounded-lg p-8 mb-12 ${isDarkMode ? 'bg-gray-800 text-white' : 'bg-white text-gray-700'}`}
          {...fadeInUp}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2 className={`text-2xl font-bold mb-6 text-center ${isDarkMode ? 'text-white' : 'text-brand-green-800'}`}>{t("Why Choose Sococom SARL?")}</h2>
          <p className="mb-8 text-center">
            {t("We offer a range of services designed to meet the diverse needs of our clients. From express air freight for urgent shipments to cost-effective solutions for large-scale cargo, we have the capabilities to handle it all. Our state-of-the-art tracking system allows you to monitor the progress of your shipment in real-time, providing peace of mind and complete transparency throughout the entire process.")}
          </p>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8">
            {reasons.map((item, index) => (
              <div key={index} className="flex flex-col items-center">
                <item.icon className={`text-4xl mb-2 ${isDarkMode ? 'text-brand-yellow-500' : 'text-brand-green-600'}`} />
                <h3 className={`text-lg font-semibold mb-2 text-center ${isDarkMode ? 'text-white' : 'text-brand-green-800'}`}>{item.title}</h3>
                <p className="text-center">{item.text}</p>
              </div>
            ))}
          </div>
        </motion.div>

        <motion.div
          className={`bg-brand-green-700 ${isDarkMode ? 'bg-gray-800' : ''} text-white shadow-lg rounded-lg p-8`}
          {...fadeInUp}
          transition={{ duration: 0.5, delay: 0.5 }}
        >
          <h2 className="text-2xl font-bold mb-4 text-center">{t("Ready to Optimize Your Logistics?")}</h2>
          <p className="text-center mb-6">{t("Let's discuss how Sococom can transform your supply chain and drive your business forward.")}</p>
          <div className="text-center">
            <a href="/contact" className="bg-brand-yellow-500 hover:bg-brand-yellow-600 text-brand-green-900 font-bold py-2 px-6 rounded-full transition duration-300">
              {t("Contact Us Today")}
            </a>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default About;

