import React from 'react';
import Hero from '../components/Hero';
import ServicesOverview from '../components/ServicesOverview';
import WhyChooseUs from '../components/WhyChooseUs';
import CallToAction from '../components/CallToAction';

const Home = ({ isDarkMode }) => {
  return (
    <div className={`${isDarkMode ? 'bg-gray-900 text-white' : 'bg-white text-gray-900'}`}>
      <Hero isDarkMode={isDarkMode} />
      <ServicesOverview  isDarkMode={isDarkMode}/>
      <WhyChooseUs isDarkMode={isDarkMode} />
      <CallToAction isDarkMode={isDarkMode} />
    </div>
  );
};

export default Home;

