import React, { useState, useEffect, useCallback } from 'react';
import { Link } from 'react-router-dom';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';
import service1 from '../assets/images/12.jpg';
import service2 from '../assets/images/11.jpg';
import service3 from '../assets/images/15.jpg';
import service4 from '../assets/images/8.jpg';
import service5 from '../assets/images/21.jpg';
import service6 from '../assets/images/24.jpg';
import service7 from '../assets/images/28.jpg';
import service8 from '../assets/images/31.jpg';
import service9 from '../assets/images/general-trade.jpg';

const ServicesOverview = ({ isDarkMode }) => {
  const { t } = useTranslation();

  const services = [
    { image: service1, title: t('Air Freight'), description: t('Fast and efficient air transportation for time-sensitive cargo.') },
    { image: service2, title: t('Sea Freight'), description: t('Cost-effective ocean shipping solutions for large volumes.') },
    { image: service3, title: t('Road Transport'), description: t('Flexible and extensive road transportation network across regions.') },
    { image: service4, title: t('Warehousing'), description: t('Secure storage and inventory management services.') },
    { image: service5, title: t('Import/Export'), description: t('Comprehensive import and export services for global trade.') },
    { image: service6, title: t('Project Cargo'), description: t('Specialized handling for oversized and complex project cargo.') },
    { image: service7, title: t('Business Consultation'), description: t('Expert advice to improve your logistics operations.') },
    { image: service8, title: t('Hospitality'), description: t('Premium hospitality services for your logistics needs.') },
    { image: service9, title: t('General Trade'), description: t('Comprehensive import and export services for global trade.') },
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % services.length);
  }, [services.length]);

  const prevSlide = useCallback(() => {
    setCurrentIndex((prevIndex) => (prevIndex - 1 + services.length) % services.length);
  }, [services.length]);

  useEffect(() => {
    const interval = setInterval(nextSlide, 5000);
    return () => clearInterval(interval);
  }, [nextSlide]);

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === 'ArrowLeft') {
        prevSlide();
      } else if (event.key === 'ArrowRight') {
        nextSlide();
      }
    };

    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [nextSlide, prevSlide]);

  return (
    <div className={`py-16 ${isDarkMode ? 'bg-gray-900' : 'bg-brand-green-50'}`}>
      <div className="container mx-auto px-4">
        <h2 className={`text-3xl font-bold mb-8 text-center ${isDarkMode ? 'text-white' : 'text-brand-green-800'}`}>{t('Our Services')}</h2>
        <div className="relative">
          <div className="flex overflow-hidden">
            {[...Array(4)].map((_, i) => {
              const serviceIndex = (currentIndex + i) % services.length;
              const service = services[serviceIndex];
              return (
                <div key={serviceIndex} className="w-full md:w-1/2 lg:w-1/4 px-2 flex-shrink-0">
                  <Link to={`/services#${service.title.toLowerCase().replace(' ', '-')}`} className="block">
                    <div className={`rounded-lg shadow-md overflow-hidden hover:shadow-lg transition duration-300 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}>
                      <img src={service.image} alt={service.title} className="w-full h-48 object-cover" />
                      <div className="p-6">
                        <h3 className={`text-xl font-semibold mb-2 ${isDarkMode ? 'text-white' : 'text-brand-green-700'}`}>{service.title}</h3>
                        <p className={`${isDarkMode ? 'text-gray-300' : 'text-brand-green-600'}`}>{service.description}</p>
                      </div>
                    </div>
                  </Link>
                </div>
              );
            })}
          </div>
          <button onClick={prevSlide} className="absolute top-1/2 left-2 transform -translate-y-1/2 bg-yellow-500 text-white p-2 rounded-full">
            <FaChevronLeft />
          </button>
          <button onClick={nextSlide} className="absolute top-1/2 right-2 transform -translate-y-1/2 bg-yellow-500 text-white p-2 rounded-full">
            <FaChevronRight />
          </button>
        </div>
        <div className="flex justify-center mt-4">
          {services.map((_, index) => (
            <button
              key={index}
              onClick={() => setCurrentIndex(index)}
              className={`w-3 h-3 rounded-full mx-1 ${
                index === currentIndex ? 'bg-yellow-700' : 'bg-yellow-300'
              }`}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ServicesOverview;

