import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import heroBg from '../assets/images/hero-bg.jpg';

const Hero = ({ isDarkMode }) => {
  const { t } = useTranslation();
  return (
    <div
      className={`relative bg-cover bg-center h-screen flex items-center ${isDarkMode ? 'bg-gray-900' : ''}`}
      style={{ backgroundImage: `url(${heroBg})` }}
    >
      <div className="container mx-auto px-4 z-10">
        <div className={`max-w-2xl ${isDarkMode ? 'bg-black bg-opacity-50' : 'bg-black bg-opacity-30'} p-8 rounded-lg shadow-lg backdrop-filter backdrop-blur-sm`}>
          <h1 className="text-5xl font-bold mb-4 text-white text-shadow-lg">{t("Global Logistics Solutions")}</h1>
          <p className="text-xl mb-8 text-white font-semibold text-shadow">{t("Efficient, reliable, and comprehensive shipping services across air, sea, and land")}</p>
          <div className="flex space-x-4">
            <Link to="/services" className="bg-brand-yellow-500 text-brand-green-900 font-bold py-2 px-6 rounded-full hover:bg-brand-yellow-600 transition duration-300">
              {t("Our Services")}
            </Link>
            <Link to="/contact" className="bg-brand-green-600 text-white font-bold py-2 px-6 rounded-full hover:bg-brand-green-700 transition duration-300">
              {t("Get a Quote")}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;

