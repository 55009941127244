import React, { useState } from "react";
import { motion } from "framer-motion";

const ServiceCard = ({ title, description, image, icon: Icon, index, isDarkMode }) => {
  const [isExpanded, setIsExpanded] = useState(false);

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <motion.div
      className="flex flex-col items-center mb-12"
      initial={{ opacity: 0, y: 50 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5, delay: index * 0.1 }}
    >
      <motion.div
        className={`w-full rounded-lg shadow-lg overflow-hidden transition-all duration-300 hover:shadow-2xl hover:scale-105 ${
          isDarkMode ? 'bg-gray-800' : 'bg-white'
        }`}
        whileHover={{ y: -5 }}
      >
        <div className="flex flex-col">
          <div className="w-full">
            <motion.img
              src={image}
              alt={title}
              className="w-full h-64 object-cover"
              whileHover={{ scale: 1.05 }}
              transition={{ duration: 0.3 }}
            />
          </div>
          <div className="p-6">
            <h3 className={`text-2xl font-bold mb-4 flex items-center ${isDarkMode ? 'text-gray-200' : 'text-gray-800'}`}>
              <Icon className={`mr-3 text-3xl ${isDarkMode ? 'text-yellow-400' : 'text-green-500'}`} />
              {title}
            </h3>
            <p className={`mb-4 ${isExpanded ? '' : 'line-clamp-3'} ${isDarkMode ? 'text-gray-300' : 'text-gray-600'}`}>
              {description}
            </p>
            <button
              className={`mt-4 px-4 py-2 rounded-full transition duration-300 ${
                isDarkMode ? 'bg-yellow-400 text-gray-800 hover:bg-yellow-300' : 'bg-green-500 text-white hover:bg-green-400'
              }`}
              onClick={toggleExpand}
            >
              {isExpanded ? 'Read Less' : 'Read More'}
            </button>
          </div>
        </div>
      </motion.div>
    </motion.div>
  );
};

export default ServiceCard;


