import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import logo from "../assets/images/logo.png";
import { FaMoon, FaSun } from "react-icons/fa";

const Header = ({ isDarkMode, toggleDarkMode }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <header className="bg-white dark:bg-gray-800 shadow-lg">
      <div className="container mx-auto px-4">
        <div className="flex justify-between items-center py-4">
          <Link to="/" className="flex items-center space-x-2">
            <img src={logo} alt="Sococomsarl Logo" className="h-12 w-auto" />
            <span className="text-2xl font-bold text-brand-green-600 dark:text-brand-green-400">
              SOCOCOM SARL
            </span>
          </Link>
          <nav className="hidden md:flex space-x-6 items-center">
            <NavLink to="/">{t("Home")}</NavLink>
            <NavLink to="/services">{t("Services")}</NavLink>
            <NavLink to="/about">{t("About Us")}</NavLink>
            <NavLink to="/contact">{t("Contact Us")}</NavLink>
            <LanguageSwitcher changeLanguage={changeLanguage} />
            <button
              onClick={toggleDarkMode}
              className="p-2 rounded-full bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200"
            >
              {isDarkMode ? <FaSun /> : <FaMoon />}
            </button>
          </nav>
          <div className="md:hidden flex items-center">
            <LanguageSwitcher changeLanguage={changeLanguage} />
            <button
              onClick={toggleDarkMode}
              className="ml-4 p-2 rounded-full bg-gray-200 dark:bg-gray-600 text-gray-800 dark:text-gray-200"
            >
              {isDarkMode ? <FaSun /> : <FaMoon />}
            </button>
            <button
              className="ml-4 focus:outline-none text-brand-green-600 dark:text-brand-green-400"
              onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            >
              <svg
                className="w-6 h-6"
                fill="none"
                stroke="currentColor"
                viewBox="0 0 24 24"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth={2}
                  d="M4 6h16M4 12h16m-7 6h7"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>
      <div
        className={`md:hidden bg-brand-green-50 dark:bg-gray-700 transition-all duration-300 ease-in-out ${
          isMobileMenuOpen ? "max-h-56" : "max-h-0 overflow-hidden"
        }`}
      >
        <nav className="flex flex-col space-y-2 px-4 py-2">
          <NavLink to="/" onClick={() => setIsMobileMenuOpen(false)}>
            {t("Home")}
          </NavLink>
          <NavLink to="/services" onClick={() => setIsMobileMenuOpen(false)}>
            {t("Services")}
          </NavLink>
          <NavLink to="/about" onClick={() => setIsMobileMenuOpen(false)}>
            {t("About Us")}
          </NavLink>
          <NavLink to="/contact" onClick={() => setIsMobileMenuOpen(false)}>
            {t("Contact Us")}
          </NavLink>
        </nav>
      </div>
    </header>
  );
};

const NavLink = ({ to, children, onClick }) => (
  <Link
    to={to}
    className="text-brand-green-800 dark:text-brand-green-200 hover:text-brand-yellow-600 dark:hover:text-brand-yellow-400 font-medium transition-colors duration-300"
    onClick={onClick}
  >
    {children}
  </Link>
);

const LanguageSwitcher = ({ changeLanguage }) => (
  <div className="flex items-center space-x-2">
    <button
      onClick={() => changeLanguage("en")}
      className="text-sm text-brand-green-800 dark:text-brand-green-200 hover:text-brand-yellow-600 dark:hover:text-brand-yellow-400"
    >
      EN
    </button>
    <span className="text-brand-green-800 dark:text-brand-green-200">|</span>
    <button
      onClick={() => changeLanguage("fr")}
      className="text-sm text-brand-green-800 dark:text-brand-green-200 hover:text-brand-yellow-600 dark:hover:text-brand-yellow-400"
    >
      FR
    </button>
  </div>
);

export default Header;

