export const resources = {
  en: {
    translation: {
      // English translations
      "SOCOCOM SARL": "SOCOCOM SARL",
      "Your trusted partner in global logistics solutions.":
        "Your trusted partner in global logistics solutions.",
      "Quick Links": "Quick Links",
      "Home ": "Home",
      "Services ": "Services",
      "About Us": "About Us",
      Contact: "Contact",
      "Our Services": "Our Services",
      "Sea Freight": "Sea Freight",
      "Air Freight": "Air Freight",
      "Land Transport": "Land Transport",
      Warehousing: "Warehousing",
      "Import/Export": "Import/Export",
      "Project Cargo": "Project Cargo",
      "Business Consultation": "Business Consultation",
      Hospitality: "Hospitality",
      "Contact Us": "Contact Us",
      "All rights reserved.": "All rights reserved.",
      "Developed and Maintained by": "Developed and Maintained by",

      // About Page Translations
      "ABOUT SOCOCOM SARL": "ABOUT SOCOCOM SARL",
      "Your Trusted Logistics Partner": "Your Trusted Logistics Partner",
      "At Sococom, we're more than just a transport and logistics company. We're your strategic partner in navigating the complexities of global supply chains. With years of experience and a commitment to excellence, we deliver tailored solutions that drive your business forward.":
        "At Sococom, we're more than just a transport and logistics company. We're your strategic partner in navigating the complexities of global supply chains. With years of experience and a commitment to excellence, we deliver tailored solutions that drive your business forward.",
      "Our team of experts combines industry knowledge with cutting-edge technology to ensure your goods are delivered safely, efficiently, and on time. From sea freight to air cargo, warehousing to customs clearance, we offer end-to-end solutions that simplify your logistics operations.":
        "Our team of experts combines industry knowledge with cutting-edge technology to ensure your goods are delivered safely, efficiently, and on time. From sea freight to air cargo, warehousing to customs clearance, we offer end-to-end solutions that simplify your logistics operations.",
      "Our Mission": "Our Mission",
      "To provide efficient and reliable logistics solutions that exceed our customers' expectations, driving growth and success for businesses worldwide.":
        "To provide efficient and reliable logistics solutions that exceed our customers' expectations, driving growth and success for businesses worldwide.",
      "Our Vision": "Our Vision",
      "To be the leading global logistics provider, known for innovation, sustainability, and unparalleled customer service in every corner of the world.":
        "To be the leading global logistics provider, known for innovation, sustainability, and unparalleled customer service in every corner of the world.",
      "Our Values": "Our Values",
      "Integrity, Excellence, Innovation, Sustainability, and Customer-Centricity form the foundation of everything we do at Sococom.":
        "Integrity, Excellence, Innovation, Sustainability, and Customer-Centricity form the foundation of everything we do at Sococom.",
      "Why Choose Sococom SARL?": "Why Choose Sococom SARL?",
      "We offer a range of services designed to meet the diverse needs of our clients. From express air freight for urgent shipments to cost-effective solutions for large-scale cargo, we have the capabilities to handle it all. Our state-of-the-art tracking system allows you to monitor the progress of your shipment in real-time, providing peace of mind and complete transparency throughout the entire process.":
        "We offer a range of services designed to meet the diverse needs of our clients. From express air freight for urgent shipments to cost-effective solutions for large-scale cargo, we have the capabilities to handle it all. Our state-of-the-art tracking system allows you to monitor the progress of your shipment in real-time, providing peace of mind and complete transparency throughout the entire process.",
      "Extensive Experience": "Extensive Experience",
      "Years of hands-on experience in the logistics industry, equipped to handle a wide range of logistical challenges.":
        "Years of hands-on experience in the logistics industry, equipped to handle a wide range of logistical challenges.",
      "Comprehensive Services": "Comprehensive Services",
      "One-stop-shop for all logistics needs including air freight, sea freight, ground transportation, customs clearance, warehousing, and distribution.":
        "One-stop-shop for all logistics needs including air freight, sea freight, ground transportation, customs clearance, warehousing, and distribution.",
      "Global Reach": "Global Reach",
      "Wide network of partners and agents worldwide, offering efficient and cost-effective global coverage.":
        "Wide network of partners and agents worldwide, offering efficient and cost-effective global coverage.",
      "Commitment to Compliance": "Commitment to Compliance",
      "Prioritizing compliance with customs regulations and trade laws, ensuring smooth customs clearance.":
        "Prioritizing compliance with customs regulations and trade laws, ensuring smooth customs clearance.",
      "Excellent Customer Service": "Excellent Customer Service",
      "Dedicated team always available to address inquiries, provide updates, and support throughout the logistics process.":
        "Dedicated team always available to address inquiries, provide updates, and support throughout the logistics process.",
      "Cutting-Edge Technology": "Cutting-Edge Technology",
      "State-of-the-art tracking system providing real-time visibility and transparency throughout the transportation process.":
        "State-of-the-art tracking system providing real-time visibility and transparency throughout the transportation process.",
      "Ready to Optimize Your Logistics?": "Ready to Optimize Your Logistics?",
      "Let's discuss how Sococom can transform your supply chain and drive your business forward.":
        "Let's discuss how Sococom can transform your supply chain and drive your business forward.",
      "Contact Us Today": "Contact Us Today",

      // Contact Page Translations
      "Contact Us": "Contact Us",
      "Get in Touch": "Get in Touch",
      Name: "Name",
      Email: "Email",
      Message: "Message",
      "Send Message": "Send Message",
      "Contact Information": "Contact Information",
      "Business Hours": "Business Hours",
      "Monday - Friday": "Monday - Friday: 8:00 AM - 6:00 PM",
      Saturday: "Saturday: 9:00 AM - 1:00 PM",
      Sunday: "Sunday: Closed",
      "Find Us": "Find Us",

      // Home Page Translations
      "Welcome to SOCOCOM SARL": "Welcome to SOCOCOM SARL",
      "Your Trusted Partner in Global Logistics":
        "Your Trusted Partner in Global Logistics",
      "Explore Our Services": "Explore Our Services",
      "About Us": "About Us",
      "Our Expertise": "Our Expertise",
      "Sea Freight": "Sea Freight",
      "Efficient ocean freight solutions for your cargo":
        "Efficient ocean freight solutions for your cargo",
      "Air Freight": "Air Freight",
      "Fast and reliable air cargo services":
        "Fast and reliable air cargo services",
      "Land Transport": "Land Transport",
      "Comprehensive road and rail transportation":
        "Comprehensive road and rail transportation",
      Warehousing: "Warehousing",
      "Secure storage and inventory management":
        "Secure storage and inventory management",
      "Why Choose Us?": "Why Choose Us?",
      "Global Network": "Global Network",
      "Extensive network of partners worldwide":
        "Extensive network of partners worldwide",
      "Custom Solutions": "Custom Solutions",
      "Tailored logistics solutions for your unique needs":
        "Tailored logistics solutions for your unique needs",
      "24/7 Support": "24/7 Support",
      "Round-the-clock customer service": "Round-the-clock customer service",
      "Advanced Technology": "Advanced Technology",
      "State-of-the-art tracking and management systems":
        "State-of-the-art tracking and management systems",
      "Ready to optimize your supply chain?":
        "Ready to optimize your supply chain?",
      "Contact Us Now": "Contact Us Now",

      // Services Page Translations
      "Our Services": "Our Services",
      "Explore Our Comprehensive Logistics Solutions":
        "Explore Our Comprehensive Logistics Solutions",
      "At SOCOCOM SARL, we offer a wide range of logistics services to meet your business needs. Our expertise spans across various modes of transportation and supply chain solutions.":
        "At SOCOCOM SARL, we offer a wide range of logistics services to meet your business needs. Our expertise spans across various modes of transportation and supply chain solutions.",
      "Sea Freight": "Sea Freight",
      "Efficient ocean freight solutions for your cargo":
        "Efficient ocean freight solutions for your cargo",
      "We provide reliable sea freight services for all types of cargo, ensuring timely and cost-effective transportation across the globe.":
        "We provide reliable sea freight services for all types of cargo, ensuring timely and cost-effective transportation across the globe.",
      "Air Freight": "Air Freight",
      "Fast and reliable air cargo services":
        "Fast and reliable air cargo services",
      "Our air freight solutions offer speed and flexibility for time-sensitive shipments, with a global network of carriers.":
        "Our air freight solutions offer speed and flexibility for time-sensitive shipments, with a global network of carriers.",
      "Land Transport": "Land Transport",
      "Comprehensive road and rail transportation":
        "Comprehensive road and rail transportation",
      "We offer efficient land transport services, including road and rail options, for domestic and cross-border shipments.":
        "We offer efficient land transport services, including road and rail options, for domestic and cross-border shipments.",
      Warehousing: "Warehousing",
      "Secure storage and inventory management":
        "Secure storage and inventory management",
      "Our state-of-the-art warehousing facilities provide secure storage and efficient inventory management for your goods.":
        "Our state-of-the-art warehousing facilities provide secure storage and efficient inventory management for your goods.",
      "Customs Clearance": "Customs Clearance",
      "Expert handling of customs procedures":
        "Expert handling of customs procedures",
      "We simplify the customs clearance process, ensuring compliance and smooth movement of your goods across borders.":
        "We simplify the customs clearance process, ensuring compliance and smooth movement of your goods across borders.",
      "Supply Chain Solutions": "Supply Chain Solutions",
      "End-to-end supply chain management":
        "End-to-end supply chain management",
      "Our comprehensive supply chain solutions optimize your logistics operations, from sourcing to final delivery.":
        "Our comprehensive supply chain solutions optimize your logistics operations, from sourcing to final delivery.",
      "Ready to streamline your logistics?":
        "Ready to streamline your logistics?",
      "Contact us today to discuss how we can tailor our services to meet your specific needs.":
        "Contact us today to discuss how we can tailor our services to meet your specific needs.",
      "Get in Touch": "Get in Touch",
      "AIR CARGO SHIPMENT": "AIR CARGO SHIPMENT",
      "Your one-stop solution for efficient and reliable air cargo services. Whether you need to transport small parcels or heavy freight, we are here to provide you with seamless air shipment solutions tailored to your specific needs. Our team of experts is dedicated to delivering your goods safely and on time, ensuring that they reach their destination without delay. Safety and security are paramount to us. We strictly adhere to international regulations and industry standards when it comes to handling and transporting your cargo.":
        "Your one-stop solution for efficient and reliable air cargo services. Whether you need to transport small parcels or heavy freight, we are here to provide you with seamless air shipment solutions tailored to your specific needs. Our team of experts is dedicated to delivering your goods safely and on time, ensuring that they reach their destination without delay. Safety and security are paramount to us. We strictly adhere to international regulations and industry standards when it comes to handling and transporting your cargo.",

      "SEA CARGO SHIPMENT": "SEA CARGO SHIPMENT",
      "We offer comprehensive and cost-effective solutions for transporting goods across the seas, ensuring efficient delivery and seamless supply chain operations. We understand the importance of seamless supply chain management and timely delivery of goods to drive your business success. With our extensive network of shipping partners and state-of-the-art technology, we provide reliable and efficient sea cargo services tailored to your specific needs.":
        "We offer comprehensive and cost-effective solutions for transporting goods across the seas, ensuring efficient delivery and seamless supply chain operations. We understand the importance of seamless supply chain management and timely delivery of goods to drive your business success. With our extensive network of shipping partners and state-of-the-art technology, we provide reliable and efficient sea cargo services tailored to your specific needs.",

      "IMPORT & EXPORT AGENCY": "IMPORT & EXPORT AGENCY",
      "We understand the importance of efficient customs clearance and smooth logistics operations in international trade. As a trusted clearing and forwarding company, we specialize in providing comprehensive services to help businesses navigate complex customs procedures and optimize their supply chains for seamless import and export of goods. Our expert team ensures compliance with all regulations and streamlines the entire process for you.":
        "We understand the importance of efficient customs clearance and smooth logistics operations in international trade. As a trusted clearing and forwarding company, we specialize in providing comprehensive services to help businesses navigate complex customs procedures and optimize their supply chains for seamless import and export of goods. Our expert team ensures compliance with all regulations and streamlines the entire process for you.",

      "WAREHOUSING AND DISTRIBUTION": "WAREHOUSING AND DISTRIBUTION",
      "We understand the importance of efficient storage, handling, and distribution of goods in today's fast-paced business environment. With our comprehensive warehousing and distribution services, we aim to meet your unique needs and optimize your supply chain operations. Our distribution services are designed to streamline your product flow and deliver your goods to their designated destinations on time, ensuring customer satisfaction and business growth.":
        "We understand the importance of efficient storage, handling, and distribution of goods in today's fast-paced business environment. With our comprehensive warehousing and distribution services, we aim to meet your unique needs and optimize your supply chain operations. Our distribution services are designed to streamline your product flow and deliver your goods to their designated destinations on time, ensuring customer satisfaction and business growth.",

      "TRANSPORTATION SERVICES": "TRANSPORTATION SERVICES",
      "We understand the importance of reliable and efficient transportation in ensuring the smooth flow of goods in domestic and international trade. Whether you have time-sensitive shipments, perishable goods or high-value cargo, our dedicated team will work closely with leading airlines and shipping lines to arrange the most suitable carrier and mode of transport, handle all necessary documentation, and ensure the secure delivery of your goods from port of entry to final destination.":
        "We understand the importance of reliable and efficient transportation in ensuring the smooth flow of goods in domestic and international trade. Whether you have time-sensitive shipments, perishable goods or high-value cargo, our dedicated team will work closely with leading airlines and shipping lines to arrange the most suitable carrier and mode of transport, handle all necessary documentation, and ensure the secure delivery of your goods from port of entry to final destination.",

      "PROJECT CARGO HANDLING": "PROJECT CARGO HANDLING",
      "We provide comprehensive solutions for the transportation and logistics management of project cargo. Whether you are involved in large-scale infrastructure projects, heavy machinery installations, or oversized cargo shipments, we have the expertise and experience to ensure the successful execution of your project. Our project cargo handling services encompass a range of industries, including construction, oil and gas, mining, renewable energy, and more. We understand the unique challenges associated with these complex logistics operations and we are committed to delivering efficient and reliable solutions tailored to your specific needs.":
        "We provide comprehensive solutions for the transportation and logistics management of project cargo. Whether you are involved in large-scale infrastructure projects, heavy machinery installations, or oversized cargo shipments, we have the expertise and experience to ensure the successful execution of your project. Our project cargo handling services encompass a range of industries, including construction, oil and gas, mining, renewable energy, and more. We understand the unique challenges associated with these complex logistics operations and we are committed to delivering efficient and reliable solutions tailored to your specific needs.",

      "BUSINESS CONSULTATION": "BUSINESS CONSULTATION",
      "By choosing our business consulting services, you gain access to a team of experienced professionals who are dedicated to helping you overcome challenges and achieve your business objectives. We provide strategic insights, market analysis, and tailored solutions to drive growth and efficiency in your operations.":
        "By choosing our business consulting services, you gain access to a team of experienced professionals who are dedicated to helping you overcome challenges and achieve your business objectives. We provide strategic insights, market analysis, and tailored solutions to drive growth and efficiency in your operations.",

      HOSPITALITY: "HOSPITALITY",
      "We provide a range of exceptional services to make your travel experience convenient, comfortable and enjoyable. Whether you're arriving at the airport, in need of hotel reservations, planning a tour, or requiring a rental car, we have you covered. Our hospitality services ensure that every aspect of your journey is taken care of, allowing you to focus on your business or leisure activities without worry.":
        "We provide a range of exceptional services to make your travel experience convenient, comfortable and enjoyable. Whether you're arriving at the airport, in need of hotel reservations, planning a tour, or requiring a rental car, we have you covered. Our hospitality services ensure that every aspect of your journey is taken care of, allowing you to focus on your business or leisure activities without worry.",

      "Our Services": "Our Services",

      // CallToAction Component
      "Ready to Optimize Your Logistics?": "Ready to Optimize Your Logistics?",
      "Let's discuss how SOCOCOMSARL can streamline your supply chain and boost your business efficiency.":
        "Let's discuss how SOCOCOMSARL can streamline your supply chain and boost your business efficiency.",
      "Get a Quote": "Get a Quote",
      "Our Services": "Our Services",

      // Hero Component
      "Global Logistics Solutions": "Global Logistics Solutions",
      "Efficient, reliable, and comprehensive shipping services across air, sea, and land":
        "Efficient, reliable, and comprehensive shipping services across air, sea, and land",
      "Our Services": "Our Services",
      "Get a Quote": "Get a Quote",
      // ServicesOverview Component
      "Our Services": "Our Services",
      "Air Freight": "Air Freight",
      "Fast and efficient air transportation for time-sensitive cargo.":
        "Fast and efficient air transportation for time-sensitive cargo.",
      "Sea Freight": "Sea Freight",
      "Cost-effective ocean shipping solutions for large volumes.":
        "Cost-effective ocean shipping solutions for large volumes.",
      "Road Transport": "Road Transport",
      "Flexible and extensive road transportation network across regions.":
        "Flexible and extensive road transportation network across regions.",
      Warehousing: "Warehousing",
      "Secure storage and inventory management services.":
        "Secure storage and inventory management services.",
      "Import/Export": "Import/Export",
      "Comprehensive import and export services for global trade.":
        "Comprehensive import and export services for global trade.",
      "Project Cargo": "Project Cargo",
      "Specialized handling for oversized and complex project cargo.":
        "Specialized handling for oversized and complex project cargo.",
      "Business Consultation": "Business Consultation",
      "Expert advice to improve your logistics operations.":
        "Expert advice to improve your logistics operations.",
      Hospitality: "Hospitality",
      "Premium hospitality services for your logistics needs.":
        "Premium hospitality services for your logistics needs.",

      // WhyChooseUs Component
      "Why Choose SOCOCOMSARL": "Why Choose SOCOCOMSARL",
      "Sea Freight": "Sea Freight",
      "Efficient and cost-effective sea freight solutions.": "Efficient and cost-effective sea freight solutions.",
      "Air Freight": "Air Freight",
      "Fast and reliable air freight services worldwide.": "Fast and reliable air freight services worldwide.",
      "Land Transport": "Land Transport",
      "Comprehensive land transport network.": "Comprehensive land transport network.",
      "Global Coverage": "Global Coverage",
      "Extensive network covering major global routes.": "Extensive network covering major global routes.",

      //General trade
      "GENERAL TRADE": "GENERAL TRADE",
      "Our General Trade service offers a versatile solution for all your miscellaneous purchasing and sales needs. Whether you are looking to buy or sell a wide range of products, we provide a seamless and efficient trading experience. Our team is committed to ensuring that your transactions are smooth and successful, leveraging our extensive network and market expertise to meet your specific requirements.": "Our General Trade service offers a versatile solution for all your miscellaneous purchasing and sales needs. Whether you are looking to buy or sell a wide range of products, we provide a seamless and efficient trading experience. Our team is committed to ensuring that your transactions are smooth and successful, leveraging our extensive network and market expertise to meet your specific requirements.",
   

    },
  },
  fr: {
    translation: {
      // French translations
      "SOCOCOM SARL": "SOCOCOM SARL",
      "Your trusted partner in global logistics solutions.":
        "Votre partenaire de confiance en solutions logistiques globales.",
      "Quick Links": "Liens Rapides",
      Home: "Accueil",
      Services: "Services",
      "About Us": "À Propos",
      Contact: "Contact",
      "Our Services": "Nos Services",
      "Sea Freight": "Fret Maritime",
      "Air Freight": "Fret Aérien",
      "Land Transport": "Transport Terrestre",
      Warehousing: "Entreposage",
      "Import/Export": "Import/Export",
      "Project Cargo": "Cargaison de Projet",
      "Business Consultation": "Consultation d'Affaires",
      Hospitality: "Hospitalité",
      "Contact Us": "Contactez-nous",
      "All rights reserved.": "Tous droits réservés.",
      "Developed and Maintained by": "Développé et maintenu par",

      // About Page Translations
      "ABOUT SOCOCOM SARL": "À PROPOS DE SOCOCOM SARL",
      "Your Trusted Logistics Partner":
        "Votre Partenaire Logistique de Confiance",
      "At Sococom, we're more than just a transport and logistics company. We're your strategic partner in navigating the complexities of global supply chains. With years of experience and a commitment to excellence, we deliver tailored solutions that drive your business forward.":
        "Chez Sococom, nous sommes plus qu'une simple entreprise de transport et de logistique. Nous sommes votre partenaire stratégique pour naviguer dans les complexités des chaînes d'approvisionnement mondiales. Avec des années d'expérience et un engagement envers l'excellence, nous offrons des solutions sur mesure qui font avancer votre entreprise.",
      "Our team of experts combines industry knowledge with cutting-edge technology to ensure your goods are delivered safely, efficiently, and on time. From sea freight to air cargo, warehousing to customs clearance, we offer end-to-end solutions that simplify your logistics operations.":
        "Notre équipe d'experts combine la connaissance de l'industrie avec une technologie de pointe pour assurer que vos marchandises sont livrées en toute sécurité, efficacement et à temps. Du fret maritime au fret aérien, de l'entreposage au dédouanement, nous offrons des solutions de bout en bout qui simplifient vos opérations logistiques.",
      "Our Mission": "Notre Mission",
      "To provide efficient and reliable logistics solutions that exceed our customers' expectations, driving growth and success for businesses worldwide.":
        "Fournir des solutions logistiques efficaces et fiables qui dépassent les attentes de nos clients, favorisant la croissance et le succès des entreprises dans le monde entier.",
      "Our Vision": "Notre Vision",
      "To be the leading global logistics provider, known for innovation, sustainability, and unparalleled customer service in every corner of the world.":
        "Être le principal fournisseur de logistique mondiale, reconnu pour l'innovation, la durabilité et un service client inégalé dans tous les coins du monde.",
      "Our Values": "Nos Valeurs",
      "Integrity, Excellence, Innovation, Sustainability, and Customer-Centricity form the foundation of everything we do at Sococom.":
        "L'intégrité, l'excellence, l'innovation, la durabilité et l'orientation client forment la base de tout ce que nous faisons chez Sococom.",
      "Why Choose Sococom SARL?": "Pourquoi Choisir Sococom SARL ?",
      "We offer a range of services designed to meet the diverse needs of our clients. From express air freight for urgent shipments to cost-effective solutions for large-scale cargo, we have the capabilities to handle it all. Our state-of-the-art tracking system allows you to monitor the progress of your shipment in real-time, providing peace of mind and complete transparency throughout the entire process.":
        "Nous offrons une gamme de services conçus pour répondre aux besoins variés de nos clients. Du fret aérien express pour les expéditions urgentes aux solutions rentables pour les cargaisons à grande échelle, nous avons les capacités de tout gérer. Notre système de suivi de pointe vous permet de surveiller la progression de votre expédition en temps réel, offrant tranquillité d'esprit et transparence totale tout au long du processus.",
      "Extensive Experience": "Expérience Étendue",
      "Years of hands-on experience in the logistics industry, equipped to handle a wide range of logistical challenges.":
        "Des années d'expérience pratique dans l'industrie logistique, équipés pour gérer un large éventail de défis logistiques.",
      "Comprehensive Services": "Services Complets",
      "One-stop-shop for all logistics needs including air freight, sea freight, ground transportation, customs clearance, warehousing, and distribution.":
        "Guichet unique pour tous les besoins logistiques, y compris le fret aérien, le fret maritime, le transport terrestre, le dédouanement, l'entreposage et la distribution.",
      "Global Reach": "Portée Mondiale",
      "Wide network of partners and agents worldwide, offering efficient and cost-effective global coverage.":
        "Large réseau de partenaires et d'agents dans le monde entier, offrant une couverture mondiale efficace et rentable.",
      "Commitment to Compliance": "Engagement envers la Conformité",
      "Prioritizing compliance with customs regulations and trade laws, ensuring smooth customs clearance.":
        "Priorisant la conformité aux réglementations douanières et aux lois commerciales, assurant un dédouanement fluide.",
      "Excellent Customer Service": "Service Client Excellent",
      "Dedicated team always available to address inquiries, provide updates, and support throughout the logistics process.":
        "Équipe dédiée toujours disponible pour répondre aux demandes, fournir des mises à jour et offrir un soutien tout au long du processus logistique.",
      "Cutting-Edge Technology": "Technologie de Pointe",
      "State-of-the-art tracking system providing real-time visibility and transparency throughout the transportation process.":
        "Système de suivi à la pointe de la technologie offrant une visibilité en temps réel et une transparence tout au long du processus de transport.",
      "Ready to Optimize Your Logistics?":
        "Prêt à Optimiser Votre Logistique ?",
      "Let's discuss how Sococom can transform your supply chain and drive your business forward.":
        "Discutons de la façon dont Sococom peut transformer votre chaîne d'approvisionnement et faire avancer votre entreprise.",
      "Contact Us Today": "Contactez-nous Aujourd'hui",

      // Contact Page Translations
      "Contact Us": "Contactez-nous",
      "Get in Touch": "Entrez en contact",
      Name: "Nom",
      Email: "Email",
      Message: "Message",
      "Send Message": "Envoyer le message",
      "Contact Information": "Informations de contact",
      "Business Hours": "Heures d'ouverture",
      "Monday - Friday": "Lundi - Vendredi : 8h00 - 18h00",
      Saturday: "Samedi : 9h00 - 13h00",
      Sunday: "Dimanche : Fermé",
      "Find Us": "Trouvez-nous",

      // Home Page Translations
      "Welcome to SOCOCOM SARL": "Bienvenue chez SOCOCOM SARL",
      "Your Trusted Partner in Global Logistics":
        "Votre Partenaire de Confiance en Logistique Mondiale",
      "Explore Our Services": "Découvrez Nos Services",
      "About Us": "À Propos de Nous",
      "Our Expertise": "Notre Expertise",
      "Sea Freight": "Fret Maritime",
      "Efficient ocean freight solutions for your cargo":
        "Solutions efficaces de fret maritime pour votre cargaison",
      "Air Freight": "Fret Aérien",
      "Fast and reliable air cargo services":
        "Services de fret aérien rapides et fiables",
      "Land Transport": "Transport Terrestre",
      "Comprehensive road and rail transportation":
        "Transport routier et ferroviaire complet",
      Warehousing: "Entreposage",
      "Secure storage and inventory management":
        "Stockage sécurisé et gestion des inventaires",
      "Why Choose Us?": "Pourquoi Nous Choisir ?",
      "Global Network": "Réseau Mondial",
      "Extensive network of partners worldwide":
        "Vaste réseau de partenaires dans le monde entier",
      "Custom Solutions": "Solutions Sur Mesure",
      "Tailored logistics solutions for your unique needs":
        "Solutions logistiques adaptées à vos besoins uniques",
      "24/7 Support": "Support 24/7",
      "Round-the-clock customer service":
        "Service client disponible 24h/24 et 7j/7",
      "Advanced Technology": "Technologie Avancée",
      "State-of-the-art tracking and management systems":
        "Systèmes de suivi et de gestion à la pointe de la technologie",
      "Ready to optimize your supply chain?":
        "Prêt à optimiser votre chaîne d'approvisionnement ?",
      "Contact Us Now": "Contactez-Nous Maintenant",

      // Services Page Translations
      "Our Services": "Nos Services",
      "Explore Our Comprehensive Logistics Solutions":
        "Découvrez Nos Solutions Logistiques Complètes",
      "At SOCOCOM SARL, we offer a wide range of logistics services to meet your business needs. Our expertise spans across various modes of transportation and supply chain solutions.":
        "Chez SOCOCOM SARL, nous offrons une large gamme de services logistiques pour répondre à vos besoins commerciaux. Notre expertise s'étend à divers modes de transport et solutions de chaîne d'approvisionnement.",
      "Sea Freight": "Fret Maritime",
      "Efficient ocean freight solutions for your cargo":
        "Solutions efficaces de fret maritime pour votre cargaison",
      "We provide reliable sea freight services for all types of cargo, ensuring timely and cost-effective transportation across the globe.":
        "Nous fournissons des services de fret maritime fiables pour tous types de cargaisons, assurant un transport ponctuel et rentable à travers le monde.",
      "Air Freight": "Fret Aérien",
      "Fast and reliable air cargo services":
        "Services de fret aérien rapides et fiables",
      "Our air freight solutions offer speed and flexibility for time-sensitive shipments, with a global network of carriers.":
        "Nos solutions de fret aérien offrent rapidité et flexibilité pour les envois sensibles au temps, avec un réseau mondial de transporteurs.",
      "Land Transport": "Transport Terrestre",
      "Comprehensive road and rail transportation":
        "Transport routier et ferroviaire complet",
      "We offer efficient land transport services, including road and rail options, for domestic and cross-border shipments.":
        "Nous offrons des services de transport terrestre efficaces, y compris des options routières et ferroviaires, pour les expéditions nationales et transfrontalières.",
      Warehousing: "Entreposage",
      "Secure storage and inventory management":
        "Stockage sécurisé et gestion des stocks",
      "Our state-of-the-art warehousing facilities provide secure storage and efficient inventory management for your goods.":
        "Nos installations d'entreposage à la pointe de la technologie offrent un stockage sécurisé et une gestion efficace des stocks pour vos marchandises.",
      "Customs Clearance": "Dédouanement",
      "Expert handling of customs procedures":
        "Gestion experte des procédures douanières",
      "We simplify the customs clearance process, ensuring compliance and smooth movement of your goods across borders.":
        "Nous simplifions le processus de dédouanement, assurant la conformité et le mouvement fluide de vos marchandises à travers les frontières.",
      "Supply Chain Solutions": "Solutions de Chaîne d'Approvisionnement",
      "End-to-end supply chain management":
        "Gestion de la chaîne d'approvisionnement de bout en bout",
      "Our comprehensive supply chain solutions optimize your logistics operations, from sourcing to final delivery.":
        "Nos solutions complètes de chaîne d'approvisionnement optimisent vos opérations logistiques, de l'approvisionnement à la livraison finale.",
      "Ready to streamline your logistics?":
        "Prêt à rationaliser votre logistique ?",
      "Contact us today to discuss how we can tailor our services to meet your specific needs.":
        "Contactez-nous aujourd'hui pour discuter de la façon dont nous pouvons adapter nos services à vos besoins spécifiques.",
      "Get in Touch": "Contactez-nous",
      "AIR CARGO SHIPMENT": "EXPÉDITION DE FRET AÉRIEN",
      "Your one-stop solution for efficient and reliable air cargo services. Whether you need to transport small parcels or heavy freight, we are here to provide you with seamless air shipment solutions tailored to your specific needs. Our team of experts is dedicated to delivering your goods safely and on time, ensuring that they reach their destination without delay. Safety and security are paramount to us. We strictly adhere to international regulations and industry standards when it comes to handling and transporting your cargo.":
        "Votre solution unique pour des services de fret aérien efficaces et fiables. Que vous ayez besoin de transporter de petits colis ou du fret lourd, nous sommes là pour vous fournir des solutions d'expédition aérienne adaptées à vos besoins spécifiques. Notre équipe d'experts se consacre à la livraison sûre et ponctuelle de vos marchandises, garantissant qu'elles atteignent leur destination sans délai. La sécurité est primordiale pour nous. Nous respectons strictement les réglementations internationales et les normes de l'industrie en matière de manipulation et de transport de votre fret.",

      "SEA CARGO SHIPMENT": "EXPÉDITION DE FRET MARITIME",
      "We offer comprehensive and cost-effective solutions for transporting goods across the seas, ensuring efficient delivery and seamless supply chain operations. We understand the importance of seamless supply chain management and timely delivery of goods to drive your business success. With our extensive network of shipping partners and state-of-the-art technology, we provide reliable and efficient sea cargo services tailored to your specific needs.":
        "Nous offrons des solutions complètes et rentables pour le transport de marchandises par voie maritime, assurant une livraison efficace et des opérations de chaîne d'approvisionnement sans faille. Nous comprenons l'importance d'une gestion fluide de la chaîne d'approvisionnement et d'une livraison ponctuelle des marchandises pour le succès de votre entreprise. Grâce à notre vaste réseau de partenaires d'expédition et à notre technologie de pointe, nous fournissons des services de fret maritime fiables et efficaces adaptés à vos besoins spécifiques.",

      "IMPORT & EXPORT AGENCY": "AGENCE D'IMPORT-EXPORT",
      "We understand the importance of efficient customs clearance and smooth logistics operations in international trade. As a trusted clearing and forwarding company, we specialize in providing comprehensive services to help businesses navigate complex customs procedures and optimize their supply chains for seamless import and export of goods. Our expert team ensures compliance with all regulations and streamlines the entire process for you.":
        "Nous comprenons l'importance d'un dédouanement efficace et d'opérations logistiques fluides dans le commerce international. En tant qu'entreprise de dédouanement et d'expédition de confiance, nous nous spécialisons dans la fourniture de services complets pour aider les entreprises à naviguer dans les procédures douanières complexes et à optimiser leurs chaînes d'approvisionnement pour une importation et une exportation sans heurts des marchandises. Notre équipe d'experts assure la conformité à toutes les réglementations et rationalise l'ensemble du processus pour vous.",

      "WAREHOUSING AND DISTRIBUTION": "ENTREPOSAGE ET DISTRIBUTION",
      "We understand the importance of efficient storage, handling, and distribution of goods in today's fast-paced business environment. With our comprehensive warehousing and distribution services, we aim to meet your unique needs and optimize your supply chain operations. Our distribution services are designed to streamline your product flow and deliver your goods to their designated destinations on time, ensuring customer satisfaction and business growth.":
        "Nous comprenons l'importance d'un stockage, d'une manipulation et d'une distribution efficaces des marchandises dans l'environnement commercial rapide d'aujourd'hui. Avec nos services complets d'entreposage et de distribution, nous visons à répondre à vos besoins uniques et à optimiser vos opérations de chaîne d'approvisionnement. Nos services de distribution sont conçus pour rationaliser le flux de vos produits et livrer vos marchandises à leurs destinations désignées à temps, assurant la satisfaction des clients et la croissance de l'entreprise.",

      "TRANSPORTATION SERVICES": "SERVICES DE TRANSPORT",
      "We understand the importance of reliable and efficient transportation in ensuring the smooth flow of goods in domestic and international trade. Whether you have time-sensitive shipments, perishable goods or high-value cargo, our dedicated team will work closely with leading airlines and shipping lines to arrange the most suitable carrier and mode of transport, handle all necessary documentation, and ensure the secure delivery of your goods from port of entry to final destination.":
        "Nous comprenons l'importance d'un transport fiable et efficace pour assurer un flux fluide des marchandises dans le commerce national et international. Que vous ayez des expéditions urgentes, des marchandises périssables ou des cargaisons de grande valeur, notre équipe dévouée travaillera en étroite collaboration avec les principales compagnies aériennes et maritimes pour organiser le transporteur et le mode de transport les plus appropriés, gérer toute la documentation nécessaire et assurer la livraison sécurisée de vos marchandises du port d'entrée à la destination finale.",

      "PROJECT CARGO HANDLING": "GESTION DE FRET DE PROJET",
      "We provide comprehensive solutions for the transportation and logistics management of project cargo. Whether you are involved in large-scale infrastructure projects, heavy machinery installations, or oversized cargo shipments, we have the expertise and experience to ensure the successful execution of your project. Our project cargo handling services encompass a range of industries, including construction, oil and gas, mining, renewable energy, and more. We understand the unique challenges associated with these complex logistics operations and we are committed to delivering efficient and reliable solutions tailored to your specific needs.":
        "Nous fournissons des solutions complètes pour le transport et la gestion logistique des cargaisons de projet. Que vous soyez impliqué dans des projets d'infrastructure à grande échelle, des installations de machines lourdes ou des expéditions de cargaisons surdimensionnées, nous avons l'expertise et l'expérience nécessaires pour assurer l'exécution réussie de votre projet. Nos services de gestion de fret de projet couvrent une gamme d'industries, notamment la construction, le pétrole et le gaz, l'exploitation minière, les énergies renouvelables et plus encore. Nous comprenons les défis uniques associés à ces opérations logistiques complexes et nous nous engageons à fournir des solutions efficaces et fiables adaptées à vos besoins spécifiques.",

      "AIR CARGO SHIPMENT": "EXPÉDITION DE FRET AÉRIEN",
      "Your one-stop solution for efficient and reliable air cargo services. Whether you need to transport small parcels or heavy freight, we are here to provide you with seamless air shipment solutions tailored to your specific needs. Our team of experts is dedicated to delivering your goods safely and on time, ensuring that they reach their destination without delay. Safety and security are paramount to us. We strictly adhere to international regulations and industry standards when it comes to handling and transporting your cargo.":
        "Votre solution unique pour des services de fret aérien efficaces et fiables. Que vous ayez besoin de transporter de petits colis ou du fret lourd, nous sommes là pour vous fournir des solutions d'expédition aérienne adaptées à vos besoins spécifiques. Notre équipe d'experts se consacre à la livraison sûre et ponctuelle de vos marchandises, garantissant qu'elles atteignent leur destination sans délai. La sécurité est primordiale pour nous. Nous respectons strictement les réglementations internationales et les normes de l'industrie en matière de manipulation et de transport de votre cargaison.",

      "SEA CARGO SHIPMENT": "EXPÉDITION DE FRET MARITIME",
      "We offer comprehensive and cost-effective solutions for transporting goods across the seas, ensuring efficient delivery and seamless supply chain operations. We understand the importance of seamless supply chain management and timely delivery of goods to drive your business success. With our extensive network of shipping partners and state-of-the-art technology, we provide reliable and efficient sea cargo services tailored to your specific needs.":
        "Nous offrons des solutions complètes et rentables pour le transport de marchandises par voie maritime, assurant une livraison efficace et des opérations de chaîne d'approvisionnement sans faille. Nous comprenons l'importance d'une gestion fluide de la chaîne d'approvisionnement et d'une livraison ponctuelle des marchandises pour le succès de votre entreprise. Grâce à notre vaste réseau de partenaires d'expédition et à notre technologie de pointe, nous fournissons des services de fret maritime fiables et efficaces adaptés à vos besoins spécifiques.",

      "IMPORT & EXPORT AGENCY": "AGENCE D'IMPORT-EXPORT",
      "We understand the importance of efficient customs clearance and smooth logistics operations in international trade. As a trusted clearing and forwarding company, we specialize in providing comprehensive services to help businesses navigate complex customs procedures and optimize their supply chains for seamless import and export of goods. Our expert team ensures compliance with all regulations and streamlines the entire process for you.":
        "Nous comprenons l'importance d'un dédouanement efficace et d'opérations logistiques fluides dans le commerce international. En tant qu'entreprise de dédouanement et d'expédition de confiance, nous nous spécialisons dans la fourniture de services complets pour aider les entreprises à naviguer dans les procédures douanières complexes et à optimiser leurs chaînes d'approvisionnement pour une importation et une exportation sans heurts des marchandises. Notre équipe d'experts assure la conformité à toutes les réglementations et rationalise l'ensemble du processus pour vous.",

      "WAREHOUSING AND DISTRIBUTION": "ENTREPOSAGE ET DISTRIBUTION",
      "We understand the importance of efficient storage, handling, and distribution of goods in today's fast-paced business environment. With our comprehensive warehousing and distribution services, we aim to meet your unique needs and optimize your supply chain operations. Our distribution services are designed to streamline your product flow and deliver your goods to their designated destinations on time, ensuring customer satisfaction and business growth.":
        "Nous comprenons l'importance d'un stockage, d'une manipulation et d'une distribution efficaces des marchandises dans l'environnement commercial rapide d'aujourd'hui. Avec nos services complets d'entreposage et de distribution, nous visons à répondre à vos besoins uniques et à optimiser vos opérations de chaîne d'approvisionnement. Nos services de distribution sont conçus pour rationaliser le flux de vos produits et livrer vos marchandises à leurs destinations désignées à temps, assurant la satisfaction des clients et la croissance de l'entreprise.",

      "TRANSPORTATION SERVICES": "SERVICES DE TRANSPORT",
      "We understand the importance of reliable and efficient transportation in ensuring the smooth flow of goods in domestic and international trade. Whether you have time-sensitive shipments, perishable goods or high-value cargo, our dedicated team will work closely with leading airlines and shipping lines to arrange the most suitable carrier and mode of transport, handle all necessary documentation, and ensure the secure delivery of your goods from port of entry to final destination.":
        "Nous comprenons l'importance d'un transport fiable et efficace pour assurer un flux fluide des marchandises dans le commerce national et international. Que vous ayez des expéditions urgentes, des marchandises périssables ou des cargaisons de grande valeur, notre équipe dévouée travaillera en étroite collaboration avec les principales compagnies aériennes et maritimes pour organiser le transporteur et le mode de transport les plus appropriés, gérer toute la documentation nécessaire et assurer la livraison sécurisée de vos marchandises du port d'entrée à la destination finale.",

      "BUSINESS CONSULTATION": "CONSULTATION D'AFFAIRES",
      "By choosing our business consulting services, you gain access to a team of experienced professionals who are dedicated to helping you overcome challenges and achieve your business objectives. We provide strategic insights, market analysis, and tailored solutions to drive growth and efficiency in your operations.":
        "En choisissant nos services de consultation d'affaires, vous avez accès à une équipe de professionnels expérimentés dédiés à vous aider à surmonter les défis et à atteindre vos objectifs commerciaux. Nous fournissons des insights stratégiques, des analyses de marché et des solutions sur mesure pour stimuler la croissance et l'efficacité de vos opérations.",

      HOSPITALITY: "HOSPITALITÉ",
      "We provide a range of exceptional services to make your travel experience convenient, comfortable and enjoyable. Whether you're arriving at the airport, in need of hotel reservations, planning a tour, or requiring a rental car, we have you covered. Our hospitality services ensure that every aspect of your journey is taken care of, allowing you to focus on your business or leisure activities without worry.":
        "Nous offrons une gamme de services exceptionnels pour rendre votre expérience de voyage pratique, confortable et agréable. Que vous arriviez à l'aéroport, ayez besoin de réservations d'hôtel, planifiez une visite touristique ou nécessitiez une voiture de location, nous nous occupons de tout. Nos services d'hospitalité garantissent que chaque aspect de votre voyage est pris en charge, vous permettant de vous concentrer sur vos activités professionnelles ou de loisirs sans souci.",

      "Our Services": "Nos Services",

      // CallToAction Component
      "Ready to Optimize Your Logistics?":
        "Prêt à optimiser votre logistique ?",
      "Let's discuss how SOCOCOMSARL can streamline your supply chain and boost your business efficiency.":
        "Discutons de la façon dont SOCOCOMSARL peut rationaliser votre chaîne d'approvisionnement et améliorer l'efficacité de votre entreprise.",
      "Get a Quote": "Obtenir un devis",
      "Our Services": "Nos services",

      // Hero Component
      "Global Logistics Solutions": "Solutions Logistiques Globales",
      "Efficient, reliable, and comprehensive shipping services across air, sea, and land":
        "Services d'expédition efficaces, fiables et complets par voie aérienne, maritime et terrestre",
      "Our Services": "Nos Services",
      "Get a Quote": "Obtenir un Devis",

      // ServicesOverview Component
      "Our Services": "Nos Services",
      "Air Freight": "Fret Aérien",
      "Fast and efficient air transportation for time-sensitive cargo.":
        "Transport aérien rapide et efficace pour les marchandises urgentes.",
      "Sea Freight": "Fret Maritime",
      "Cost-effective ocean shipping solutions for large volumes.":
        "Solutions de transport maritime économiques pour les grands volumes.",
      "Road Transport": "Transport Routier",
      "Flexible and extensive road transportation network across regions.":
        "Réseau de transport routier flexible et étendu à travers les régions.",
      Warehousing: "Entreposage",
      "Secure storage and inventory management services.":
        "Services de stockage sécurisé et de gestion des stocks.",
      "Import/Export": "Import/Export",
      "Comprehensive import and export services for global trade.":
        "Services complets d'import-export pour le commerce mondial.",
      "Project Cargo": "Cargaison de Projet",
      "Specialized handling for oversized and complex project cargo.":
        "Manutention spécialisée pour les cargaisons de projet surdimensionnées et complexes.",
      "Business Consultation": "Consultation d'Affaires",
      "Expert advice to improve your logistics operations.":
        "Conseils d'experts pour améliorer vos opérations logistiques.",
      Hospitality: "Hospitalité",
      "Premium hospitality services for your logistics needs.":
        "Services d'hospitalité haut de gamme pour vos besoins logistiques.",

       // WhyChooseUs Component
       "Why Choose SOCOCOMSARL": "Pourquoi Choisir SOCOCOMSARL",
       "Sea Freight": "Fret Maritime",
       "Efficient and cost-effective sea freight solutions.": "Solutions de fret maritime efficaces et économiques.",
       "Air Freight": "Fret Aérien",
       "Fast and reliable air freight services worldwide.": "Services de fret aérien rapides et fiables dans le monde entier.",
       "Land Transport": "Transport Terrestre",
       "Comprehensive land transport network.": "Réseau complet de transport terrestre.",
       "Global Coverage": "Couverture Mondiale",
       "Extensive network covering major global routes.": "Vaste réseau couvrant les principales routes mondiales.",

       //Geneeal Trade
       "GENERAL TRADE": "COMMERCE GÉNÉRAL",
       "General Trade": "Commerce Général",
      "Our General Trade service offers a versatile solution for all your miscellaneous purchasing and sales needs. Whether you are looking to buy or sell a wide range of products, we provide a seamless and efficient trading experience. Our team is committed to ensuring that your transactions are smooth and successful, leveraging our extensive network and market expertise to meet your specific requirements.": "Notre service de Commerce Général offre une solution polyvalente pour tous vos besoins d'achat et de vente divers. Que vous cherchiez à acheter ou à vendre une large gamme de produits, nous vous offrons une expérience de commerce fluide et efficace. Notre équipe s'engage à garantir que vos transactions soient harmonieuses et réussies, en tirant parti de notre vaste réseau et de notre expertise du marché pour répondre à vos besoins spécifiques.",
   

    
    },
  },
};
