import React from 'react';
import { motion } from 'framer-motion';
import { FaMapMarkerAlt, FaPhone, FaEnvelope } from 'react-icons/fa';
import { useTranslation } from 'react-i18next';

const Contact = ({ isDarkMode }) => {
  const { t } = useTranslation();
  const fadeInUp = {
    initial: { opacity: 0, y: 50 },
    animate: { opacity: 1, y: 0 },
    transition: { duration: 0.5 }
  };

  return (
    <div className={`min-h-screen py-16 ${isDarkMode ? 'bg-gray-900 text-gray-200' : 'bg-gradient-to-b from-brand-green-50 to-brand-green-100'}`}>
      <div className="container mx-auto px-4">
        <motion.h1
          className={`text-4xl font-bold text-center mb-12 ${isDarkMode ? 'text-brand-yellow-400' : 'text-brand-green-800'}`}
          initial={{ opacity: 0, y: -50 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.5 }}
        >
          {t("Contact Us")}
        </motion.h1>

        <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
          <motion.div
            className={`shadow-lg rounded-lg p-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
            {...fadeInUp}
          >
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-brand-yellow-400' : 'text-brand-green-800'}`}>{t("Get in Touch")}</h2>
            <form>
              <div className="mb-4">
                <label htmlFor="name" className={`block font-bold mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{t("Name")}</label>
                <input type="text" id="name" name="name" className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-brand-green-500 ${isDarkMode ? 'bg-gray-700 border-gray-600 text-gray-200' : 'bg-white border-gray-300 text-gray-900'}`} required />
              </div>
              <div className="mb-4">
                <label htmlFor="email" className={`block font-bold mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{t("Email")}</label>
                <input type="email" id="email" name="email" className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-brand-green-500 ${isDarkMode ? 'bg-gray-700 border-gray-600 text-gray-200' : 'bg-white border-gray-300 text-gray-900'}`} required />
              </div>
              <div className="mb-4">
                <label htmlFor="message" className={`block font-bold mb-2 ${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>{t("Message")}</label>
                <textarea id="message" name="message" rows="4" className={`w-full px-3 py-2 border rounded-md focus:outline-none focus:ring-2 focus:ring-brand-green-500 ${isDarkMode ? 'bg-gray-700 border-gray-600 text-gray-200' : 'bg-white border-gray-300 text-gray-900'}`} required></textarea>
              </div>
              <button type="submit" className="bg-brand-yellow-500 hover:bg-brand-yellow-600 text-brand-green-900 font-bold py-2 px-4 rounded-md transition duration-300">{t("Send Message")}</button>
            </form>
          </motion.div>

          <motion.div
            className={`shadow-lg rounded-lg p-8 ${isDarkMode ? 'bg-gray-800' : 'bg-white'}`}
            {...fadeInUp}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <h2 className={`text-2xl font-bold mb-6 ${isDarkMode ? 'text-brand-yellow-400' : 'text-brand-green-800'}`}>{t("Contact Information")}</h2>
            <div className="space-y-4">
              <div className="flex items-start">
                <FaMapMarkerAlt className="text-brand-yellow-500 text-xl mr-4 mt-1" />
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                  Avenue Colonel Ebeya, Immeuble Comexas Africa<br />
                  Bureau 1er Étage # appartement numéro 105<br />
                  Kinshasa, Democratic Republic of Congo
                </p>
              </div>
              <div className="flex items-center">
                <FaPhone className="text-brand-yellow-500 text-xl mr-4" />
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>+243 978 110 472</p>
              </div>
              <div className="flex items-center">
                <FaPhone className="text-brand-yellow-500 text-xl mr-4" />
                <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>+243 990 203 095</p>
              </div>
              <div className="flex items-center">
                <FaEnvelope className="text-brand-yellow-500 text-xl mr-4" />
                <div>
                  <p className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>info@sococomsarl.com</p>
                </div>
              </div>
            </div>

            <div className="mt-8">
              <h3 className={`text-xl font-bold mb-4 ${isDarkMode ? 'text-brand-yellow-400' : 'text-brand-green-800'}`}>{t("Business Hours")}</h3>
              <ul className={`${isDarkMode ? 'text-gray-300' : 'text-gray-700'}`}>
                <li>{t("Monday - Friday")}</li>
                <li>{t("Saturday")}</li>
                <li>{t("Sunday")}</li>
              </ul>
            </div>
          </motion.div>
        </div>

        <motion.div
          className="mt-12"
          {...fadeInUp}
          transition={{ duration: 0.5, delay: 0.4 }}
        >
          <h2 className={`text-2xl font-bold text-center mb-6 ${isDarkMode ? 'text-brand-yellow-400' : 'text-brand-green-800'}`}>{t("Find Us")}</h2>
          <div className="aspect-w-16 aspect-h-9">
            <iframe
            title="Company Location Map"
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3978.525651850842!2d15.310208050161347!3d-4.305317495011712!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x1a6a30b8f5c87dd7%3A0x181b1872359d5997!2sAvenue%20Colonel%20Ebeya%2C%20Kinshasa!5e0!3m2!1sen!2scd!4v1651234567890!5m2!1sen!2scd"
              width="100%"
              height="450"
              style={{ border: 0 }}
              allowFullScreen=""
              loading="lazy"
              referrerPolicy="no-referrer-when-downgrade"
            ></iframe>
          </div>
        </motion.div>
      </div>
    </div>
  );
};

export default Contact;


